import containerQueriesPlugin from '@tailwindcss/container-queries'
import formsPlugin from '@tailwindcss/forms'
import typographyPlugin from '@tailwindcss/typography'
import plugin from 'tailwindcss/plugin'
// @ts-expect-error waiting for the fix in the tailwindcss-primeui package https://github.com/primefaces/tailwindcss-primeui/issues/1#issue-2307567409
import primeVue from 'tailwindcss-primeui'
import type { Config } from 'tailwindcss'

const composeColorMixWithAlpha = (colorVariable: string) =>
  `var(${colorVariable}-fallback, color-mix(in srgb, var(${colorVariable}), transparent calc(100% - <alpha-value> * 100%)))`

export default {
  content: [
    './public/**/*.html',
    './app/frontend/**/*.{vue,js,ts,jsx,tsx}',
    './app/views/**/*.erb',
    'vite.config.ts',
  ],
  safelist: ['tippy-box'],
  future: {
    hoverOnlyWhenSupported: true,
  },
  darkMode: ['variant', '&:not(.light *)'],
  theme: {
    fontFamily: {},
    fontWeight: {
      extralight: '200',
      light: '300',
      normal: '400',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    brightness: {
      30: '0.3',
      50: '0.5',
      70: '0.7',
      100: '1',
    },
    colors: {
      current: 'currentColor',
      transparent: 'transparent',
      /**
       * Static colors will not react to the color scheme changes
       * When using them make sure to always check if they are visible in the light mode
       * You might need to add something like `light:black` variant
       */
      white: '#FFFFFF',
      /**
       * Variable colors will react to the color scheme changes
       * ⚠️ Always think of them in the dark mode context ⚠️
       */
      'white-var': composeColorMixWithAlpha('--gc-global-color-white'),
      black: '#000000',
      'black-var': composeColorMixWithAlpha('--gc-global-color-black'),
      gray: {
        100: '#f2f2f2',
        200: '#e3e3e3',
        300: '#b9bbbb',
        400: '#a0a2a2',
        500: '#697170',
        600: '#484c4c',
        700: '#292e2e',
        800: '#252727',
        900: '#161717',
      },
      'gray-var': {
        300: composeColorMixWithAlpha('--gc-global-color-gray-300'),
        400: composeColorMixWithAlpha('--gc-global-color-gray-400'),
        600: composeColorMixWithAlpha('--gc-global-color-gray-600'),
        700: composeColorMixWithAlpha('--gc-global-color-gray-700'),
        800: composeColorMixWithAlpha('--gc-global-color-gray-800'),
        900: composeColorMixWithAlpha('--gc-global-color-gray-900'),
      },
      red: {
        500: '#E65557',
        550: '#F04446',
        600: '#F54032',
      },
      orange: {
        600: '#F98906',
      },
      lime: {
        200: '#CBF594',
        300: '#B1D585',
      },
      green: {
        500: '#00FF4A',
        600: '#00D12C',
      },
      emerald: {
        300: '#66D794',
        700: '#039a40',
      },
      'emerald-var': {
        300: composeColorMixWithAlpha('--gc-global-color-emerald-300'),
      },
      teal: {
        200: '#AAF0F0',
        400: '#88AEAE',
        600: '#678282',
      },
      cyan: {
        DEFAULT: '#38B3CE',
        500: '#38B3CE',
        600: '#0CD1CB',
      },
      sky: {
        500: '#00C0FF',
      },
      blue: {
        400: '#67A7E7',
        500: '#2F97FF',
      },
      purple: {
        500: '#A874E6',
        600: '#8C5BD1',
      },
      pink: {
        400: '#EC6485',
        500: '#FD4671',
      },
    },

    extend: {
      spacing: {
        5.5: '1.375rem',
        84: '21rem',
        /**
         * What does the "block" mean -> https://css-tricks.com/css-logical-properties-and-values/
         */
        'control-block': 'var(--gc-global-control-padding)',
        /**
         * What does the "inline" mean -> https://css-tricks.com/css-logical-properties-and-values/
         */
        'control-inline': 'calc(var(--gc-global-control-padding) * 1.5)',
      },
      boxShadow: {
        '2xl': '0 4px 60px rgba(0, 0, 0, 0.5)',
      },
      backgroundColor: {
        'color-inherit': 'inherit',
      },
      gradientColorStops: {
        current: 'currentColor',
      },
      borderColor: {
        'color-inherit': 'inherit',
      },
      borderWidth: {
        1.5: '1.5px',
      },
      opacity: {
        control: '0.7',
      },
      width: {
        // TODO: fix the counting here, it should follow Tailwind's rem x 4
        90: '25rem', // TODO: Change to 100: '25rem'
        96: '27.5rem', // TODO: Change to 110: '27.5rem'
        100: '30rem', // TODO: Change to 120: '30rem'
      },
      maxWidth: {
        '5xl': '62rem',
        '8xl': '100rem',
      },
      cursor: {
        inherit: 'inherit',
      },
      rotate: {
        225: '225deg',
      },
      zIndex: {
        '-10': '-10',
        25: '25',
      },
      inset: {
        36: '9.5rem',
        18: '4.85rem',
      },
      animation: {
        'appear-delayed-300': 'appear 250ms ease-in 50ms forwards',
        'appear-delayed-500': 'appear 250ms ease-in 250ms forwards',
        'appear-250': 'appear 250ms ease-in forwards',
      },
      keyframes: {
        appear: {
          from: { opacity: '0' },
          to: { opacity: '1' },
        },
      },
      textDecorationThickness: {
        unset: 'unset',
      },
      typography: (theme: (path: string) => string) => ({
        DEFAULT: {
          css: {
            whiteSpace: 'pre-line',
            a: {
              color: theme('colors.cyan'),
              textDecoration: 'underline',
              cursor: 'pointer',
              display: 'inline-block',
              '&:hover, &:focus': {
                opacity: 0.7,
              },
            },
            h1: {
              fontSize: theme('fontSize.3xl[0]'),
            },
            figure: {
              figcaption: {
                fontStyle: 'italic',
              },
            },
            // Default color scheme (now with lighter colors)
            '--tw-prose-body': theme('colors.white'),
            '--tw-prose-bold': theme('colors.white'),
            '--tw-prose-bullets': theme('colors.white'),
            '--tw-prose-captions': theme('colors.white'),
            '--tw-prose-code': theme('colors.white'),
            '--tw-prose-counters': theme('colors.white'),
            '--tw-prose-headings': theme('colors.white'),
            '--tw-prose-hr': theme('colors.gray[300]'),
            '--tw-prose-lead': theme('colors.white'),
            '--tw-prose-links': theme('colors.cyan'),
            '--tw-prose-pre-bg': theme('colors.gray[200]'),
            '--tw-prose-pre-code': theme('colors.white'),
            '--tw-prose-quote-borders': theme('colors.gray[300]'),
            '--tw-prose-quotes': theme('colors.white'),
            '--tw-prose-td-borders': theme('colors.gray[300]'),
            '--tw-prose-th-borders': theme('colors.gray[300]'),

            // Inverted color scheme (now with darker colors for contrast against a light theme)
            '--tw-prose-invert-body': theme('colors.black'),
            '--tw-prose-invert-bold': theme('colors.black'),
            '--tw-prose-invert-bullets': theme('colors.black'),
            '--tw-prose-invert-captions': theme('colors.black'),
            '--tw-prose-invert-code': theme('colors.black'),
            '--tw-prose-invert-counters': theme('colors.black'),
            '--tw-prose-invert-headings': theme('colors.black'),
            '--tw-prose-invert-hr': theme('colors.black'),
            '--tw-prose-invert-lead': theme('colors.black'),
            '--tw-prose-invert-links': theme('colors.cyan'),
            '--tw-prose-invert-pre-bg': theme('colors.gray[800]'),
            '--tw-prose-invert-pre-code': theme('colors.black'),
            '--tw-prose-invert-quote-borders': theme('colors.black'),
            '--tw-prose-invert-quotes': theme('colors.black'),
            '--tw-prose-invert-td-borders': theme('colors.gray[600]'),
            '--tw-prose-invert-th-borders': theme('colors.gray[600]'),
          },
        },
      }),
    },
  },
  plugins: [
    primeVue,
    formsPlugin,
    typographyPlugin,
    containerQueriesPlugin,
    plugin(({ addVariant, addUtilities, addComponents, theme }) => {
      addVariant(
        'gc-supports-animation',
        '@supports (animation: appear 200ms ease-in 50ms forwards)'
      )
      addVariant('supports-subgrid', '@supports (grid-template-rows: subgrid)')
      addVariant('media-hover', '@media (hover: hover) and (pointer: fine)')
      addVariant('retina', '@media (min-resolution: 2dppx)')
      addVariant('backdrop', '&::backdrop')
      addVariant('light', ':is(.light &)')
      addVariant('has-disabled', '&:has([disabled])')
      addVariant('first-column', '&[data-column-index="0"]')
      addUtilities({
        '.contain-content': {
          contain: 'content',
        },
        '.gutter-stable': {
          'scrollbar-gutter': 'stable',
        },
        '.gutter-stable-both': {
          'scrollbar-gutter': 'stable both-edges',
        },
        '.not-allowed': {
          '@apply cursor-not-allowed opacity-control': '',
        },
        '.decoration-none': {
          textDecoration: 'none',
        },
      })

      addComponents({
        '.base-control': {
          padding: `${theme('padding[control-block]')} ${theme(
            'padding[control-inline]'
          )}`,
          border: `${theme('borderWidth[DEFAULT]')} solid transparent`,
          borderRadius: theme('borderRadius.lg'),
          fontSize: theme('fontSize.base'),
          display: 'block',
        },
      })
    }),
  ],
} satisfies Config
